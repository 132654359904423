<template>
  <v-col cols="12">
    <p class="font-weight-regular">Deactivate</p>
    <v-card elevation="0" outlined>
      <v-list>
        <v-list-item class="list-item-deactivate-account">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Deactivate account
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              You can deactivate your own account
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            color="red"
            elevation="0"
            :outlined="$vuetify.breakpoint.smAndUp"
            :block="$vuetify.breakpoint.xs"
            class="font-weight-bold"
            @click="deactivateDialog = true"
          >
            Deactivate
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card>

    <v-dialog v-model="deactivateDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Deactivate your account?
          <v-spacer></v-spacer>
          <v-btn @click="deactivateDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Are you sure, you want to deactivate your account?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="deactivateDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="danger"
            class="px-5 py-5 white--text"
            elevation="0"
            @click="deactivateAccount"
          >
            Deactivate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SettingsDeactivate',
  data: () => ({
    deactivateDialog: false
  }),
  methods: {
    ...mapActions(['deactivateUser']),
    async deactivateAccount () {
      try {
        await this.deactivateUser()
        this.showAlert({ message: 'Your account deactivated' })
        this.deactivateDialog = false
        setTimeout(() => {
          this.$router.push('/login')
        }, 3000)
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    }
  }
}
</script>
